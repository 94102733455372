require('./commons/polyfills/CustomEvent');
require('./commons/polyfills/NodeList.forEach');

const DEBUG_MODE = !!(new URLSearchParams(window.location.search)).get('ypDebug');

window.YurPlanWidgets = {
    EVENT_TYPES: {
        AUTO_HEIGHT: 'autoHeight',
        CHANGE_STEP: 'changeStep',
        SCROLL_IFRAME: 'scrollIframe',
        ONLOAD: 'onload',
    },
    STEPS: {
        TICKETS: 'tickets',
        FORM: 'form',
        PRODUCTS: 'products',
        CONFIRMATION: 'confirmation',
        SUMMARY: 'summary',
        LOGIN: 'login',
        PASSWORD: 'password',
    },
    ACTIVATION_CODE_QUERYPARAM: 'ypActivationCode',
    GENERIC_CODE_QUERYPARAM: 'ypCode',
    ypFrames: [],
    codeAlreadySent: false,
    getParams(ypFrame) {
        const widgetId = ypFrame.attributes['data-id']?.value || null;
        return {
            class: 'yurplan-iframe-widget',
            yid: widgetId,
            id: `yurplanwidget${widgetId}`,
            scrolling: 'auto',
        };
    },

    getStyleLink() {
        return `
            margin: 10px 0 15px 0 !important;
            font-size: 13px !important;
            font-family: Helvetica, Arial, sans-serif !important;
            text-decoration: none !important;
            color: #ddd !important;
            display: block !important;`;
    },

    iframeMessageHandler(iframe, e) {
        if (DEBUG_MODE) {
            console.debug('yp', 'iframeMessageHandler', e);
        }
        const event = new CustomEvent(e.data.type);
        event.data = {
            id: e.data.id,
            orgaId: e.data.orgaId,
        };
        switch (e.data.type) {
            case this.EVENT_TYPES.AUTO_HEIGHT:
                event.data = {
                    ...event.data,
                    ...e.data.data,
                };
                break;
            case this.EVENT_TYPES.CHANGE_STEP:
                event.data = {
                    ...event.data,
                    ...e.data.data,
                };
                break;
            case this.EVENT_TYPES.ONLOAD:
                this.passActivationCode();
                this.passGenericCode();
                return;
            default:
                break;
        }
        window.dispatchEvent(event);
    },

    autoHeightHandler(iframe, e) {
        let { id } = e.data;
        id = id || e.data.orgaId;
        if (!iframe) return;
        const hasAutoHeight = iframe.attributes.height?.value === '100%';
        if (!hasAutoHeight) return;

        const iFrameSize = Number.parseInt(e.data.height, 10);
        iframe.style.height = `${iFrameSize}px`;
    },

    scrollIframeHandler(iframe, e) {
        let { orgaId: id } = e.data;
        id = id || e.data.id;

        if (!iframe) return;

        iframe.scrollIntoView();
    },

    changeStepHandler(iframe, e) {
        let { id } = e.data;
        id = id || e.data.orgaId;
        this.changeStepCustomHandler(e);
        if (!iframe) return;
        let width = '100%';
        if (!e.data.width_auto && e.data.width) {
            ({ width } = e.data);
        }
        iframe.width = width;
    },

    changeStepCustomHandler(/* e */) {
        /* custom behavior */
    },

    initFrame(ypFrame) {
        if (DEBUG_MODE) {
            console.debug('yp', 'initFrame', { ypFrame });
        }
        const params = this.getParams(ypFrame);
        Object.entries(params).forEach(([key, value]) => {
            ypFrame.setAttribute(key, value);
        });

        const eventListeners = {
            message: this.iframeMessageHandler.bind(this, ypFrame),
            scrollIframe: this.scrollIframeHandler.bind(this, ypFrame),
            changeStep: this.changeStepHandler.bind(this, ypFrame),
            autoHeight: this.autoHeightHandler.bind(this, ypFrame),
        };

        Object.entries(eventListeners).forEach(([eventType, listener]) => {
            window.addEventListener(eventType, listener.bind(this), false);
        });

        // Style footer link
        const link = document.querySelector(`.yurplan-widget-link-${params.yid}`);
        if (link) {
            link.style.cssText = this.getStyleLink();
        }
    },

    passActivationCode() {
        const code = (new URLSearchParams(window.location.search)).get(this.ACTIVATION_CODE_QUERYPARAM);
        if (code !== null && code !== '') {
            document.querySelectorAll('iframe.yurplan-iframe-widget').forEach((e) => {
                const message = {
                    action: 'passActivationCode',
                    data: code,
                };
                if (DEBUG_MODE) {
                    console.debug('yp', 'passGenericCode', { message });
                }
                e.contentWindow.postMessage(message, '*');
            });
        }
    },

    passGenericCode() {
        if (this.codeAlreadySent) return;
        this.codeAlreadySent = true;
        const code = (new URLSearchParams(window.location.search)).get(this.GENERIC_CODE_QUERYPARAM);
        if (code !== null && code !== '') {
            const message = {
                action: 'passGenericCode',
                data: code,
            };
            if (DEBUG_MODE) {
                console.debug('yp', 'passGenericCode', { code });
            }
            document.querySelectorAll('iframe.yurplan-iframe-widget').forEach((e) => {
                e.contentWindow.postMessage(message, '*');
            });
        }
    },

    init() {
        this.ypFrames = document.querySelectorAll('.yurplan-widget-container iframe');
        this.ypFrames.forEach(this.initFrame, this);
    },
};

window.YurPlanWidgets.init();
