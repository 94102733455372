/* eslint-disable */
/*
 * NodeList.forEach polyfill
 * Source: https://gist.github.com/bob-lee/e7520bfcdac266e5490f40c2759cc955
 */
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}